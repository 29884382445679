/* ****************************************** */
/* Time */
/* ****************************************** */

// Delay > Initx
const _delay_initx_arr                      = [200.0, 300.0, 400.0];        // Hvis for rask > Path "henger igjen" i dropdown header-issue
const delay_initx_load                      = _delay_initx_arr[0];          // 200; // 600  // 2000 //
const delay_initx_loading                   = _delay_initx_arr[1];          // 300; // 800  // 2500 //
const delay_initx_fetch                     = _delay_initx_arr[2];          // 400; // 1000 // 3000 // kommer alltid sist: get_doc_header_arr_exe)
const delay_initx_header_ref                = 0.0;                          //   0; // 400  // 1000 // Ubrukt fordi hardkodet (0) > Hardkodet (0) > Abs. minimum for dynamisk (300) > Brukt i header (init)

// Delay > Cont
const delay_arg_0                           = 0.0;      // Når delay sendes som arg og denne er 0 / ikke-eksisterende
const delay_load_fast                       = 10.0;     // DEV > REFAC navn til delay_is_load_done_fast, Redux load, 50: For kort -> render issue
const delay_load                            = 200.0;    // Redux load, 50: For kort -> render issue
const delay_warning_qf                      = 100.0;    // Søkeord > FIX_header_warning
const delay_ref                             = 10.0;     // Etter init, brukt i header. Av og til for kjapp for Landing, men spiller kanksje ingen rolle (er uansett oppdatert på de andre sidene)
const delay_route                           = 100.0;    // ...
const delay_route_fetch                     = 0.0;      // 1000 (hvis linker til riktig side, men ikke scroller til riktig blokk)
const delay_path_yy_toggle                  = 200.0;    // ...
const delay_footer_load                     = 1000.0;   // Alltid load sist
const delay_scroll_window                   = 300.0;    // Standard
const delay_scroll_1                        = 100.0;    // Kanskje litt rask
const delay_scroll_2_push_yp                = 200.0;    // -"-
const delay_doc_route                       = 100.0;    // Async redirect til riktig route, etter at doc setter riktig path

// Duration
const duration_token_refresh                = 1000.0 * 60.0 * 4.0;

// Animation
const ani_delay_circle_click_remove_cls     = 500.0;    // ms
const ani_ease_in_progress_bar_transition   = 0.1;      // s


export {

    // Delay > Initx
    delay_initx_load,
    delay_initx_loading,
    delay_initx_fetch,
    delay_initx_header_ref,

    // Delay
    delay_arg_0,
    delay_load_fast,
    delay_load,
    delay_warning_qf,
    delay_ref,
    delay_route,
    delay_route_fetch,
    delay_path_yy_toggle,
    delay_footer_load,
    delay_scroll_window,
    delay_scroll_1,
    delay_scroll_2_push_yp,
    delay_doc_route,

    // Duration
    duration_token_refresh,

    // Ani
    ani_delay_circle_click_remove_cls,
    ani_ease_in_progress_bar_transition,

};