/* ****************************************** */
/* Master */
/* ****************************************** */

const _debug_master_initx                                       = false;    // Default: false

const _debug_master_do_navigate_all                             = false;    // Do_navigating
const _debug_master_signup_all                                  = false;
const _debug_master_signin_all                                  = false;
const _debug_master_click_blokk_link_all                        = false;


/* ****************************************** */
/* App */
/* ****************************************** */

// Landing
let debug_app_root_initx                                        = false;    // Initx


/* ****************************************** */
/* Context */
/* ****************************************** */

// Initx
let debug_auth_initx                                            = false;    // Initx
let debug_auth_initx_cont                                       = false;    // Initx

// Signup > Console
let debug_auth_signup_fetch_signup_user                         = false;
let debug_auth_signup_fetch_get_user_profil_local_storage       = false;
let debug_auth_fetch_confirmation_redirect                      = false;
let debug_auth_fetch_signout_user                               = false;

// Signin > Console
let debug_auth_fetch_await_signin_user                          = false; // debugger kun username og pass


/* ****************************************** */
/* Components */
/* ****************************************** */

// Header
let debug_header_doc_header_arr                                 = 0;        // Initx

// Author
let debug_author_click_user_profil_details_do_navigate          = false;    // Do_navigating

// Path
let debug_path_get_doc                                          = false;    // Initx
let debug_path_click_path_item_do_navigate                      = false;    // Do_navigating


/* ****************************************** */
/* Pages */
/* ****************************************** */

// Doc
let debug_doc_click_blokk_do_navigate                           = false;
let debug_doc_click_blokk_link                                  = false;
let debug_doc_click_doc_do_navigate                             = false;
const debug_doc_bg_blank_col                                    = false;    // false: Ingen debug, true: Debug

// Doc router
let debug_doc_router_get_doc_slug_async                         = false;

// Landing
let debug_landing_use_effect                                    = false;    // Initx, Override > _debug_master_initx

// Profil set
const debug_profil_set_password_update_dat                      = false;


// Reg
let debug_reg_use_effect                                        = false;
let debug_reg_signup_click_fetch_signup                         = false;
let debug_reg_click_reg_link_do_navigate                        = false;    // Do_navigating


/* ****************************************** */
/* Master logic */
/* ****************************************** */


// Initx
if (_debug_master_initx) {

    // App
    debug_app_root_initx                                        = true;     // Initx

    // Context
    debug_auth_initx                                            = true;     // Initx

    // Header
    debug_header_doc_header_arr                                 = 1;        // Initx

    // Path
    debug_path_get_doc                                          = true;     // Initx

    // Landing
    debug_landing_use_effect                                    = true;     // Initx

};

// Do_navigating > All
if (_debug_master_do_navigate_all) {

    // Components
    debug_author_click_user_profil_details_do_navigate          = true;
    debug_path_click_path_item_do_navigate                      = true;

    // Pages
    debug_reg_click_reg_link_do_navigate                        = true
    debug_doc_click_blokk_do_navigate                           = true;
    debug_doc_click_doc_do_navigate                             = true;
};

// Signup
if (_debug_master_signup_all) {

    // Reg
    debug_reg_use_effect                                        = true;
    debug_reg_signup_click_fetch_signup                         = true;

    // Auth
    debug_auth_signup_fetch_signup_user                         = true;
    debug_auth_signup_fetch_get_user_profil_local_storage       = true;
    debug_auth_fetch_confirmation_redirect                      = true;
    debug_auth_fetch_signout_user                               = true;
};

// Signin
if (_debug_master_signin_all) {
    debug_auth_fetch_await_signin_user                          = true;
};

// Doc > Click browser-link
if (_debug_master_click_blokk_link_all) {

    // Doc
    debug_doc_click_blokk_link                                  = true;

    // Doc router
    debug_doc_router_get_doc_slug_async                         = true;
};


/* ****************************************** */
/* Export */
/* ****************************************** */

export {

    /* ****************************************** */
    /* App */
    /* ****************************************** */

    debug_app_root_initx,


    /* ****************************************** */
    /* Context */
    /* ****************************************** */

    // Initx / Cont
    debug_auth_initx,
    debug_auth_initx_cont,

    // Signup
    debug_auth_signup_fetch_signup_user,
    debug_auth_signup_fetch_get_user_profil_local_storage,
    debug_auth_fetch_confirmation_redirect,
    debug_auth_fetch_signout_user,

    // Signin
    debug_auth_fetch_await_signin_user,


    /* ****************************************** */
    /* Components */
    /* ****************************************** */

    // Header
    debug_header_doc_header_arr,

    // Author
    debug_author_click_user_profil_details_do_navigate,

    // Path
    debug_path_get_doc,
    debug_path_click_path_item_do_navigate,


    /* ****************************************** */
    /* Pages */
    /* ****************************************** */

    // Doc
    debug_doc_click_blokk_do_navigate,
    debug_doc_click_blokk_link,
    debug_doc_click_doc_do_navigate,
    debug_doc_bg_blank_col,

    // Doc router
    debug_doc_router_get_doc_slug_async,

    // Landing
    debug_landing_use_effect,

    // Profil set
    debug_profil_set_password_update_dat,

    // Reg
    debug_reg_use_effect,
    debug_reg_signup_click_fetch_signup,
    debug_reg_click_reg_link_do_navigate

};