import {
    createAsyncThunk,
    createSlice
}                                                   from "@reduxjs/toolkit";

import {
    human_lang_default_hardkodet
}                                                   from "../../innstillinger/innstillinger";

import {
    http_method_post,

    http_headers_content_type_application_json,

    http_status_code_200,
}                                                   from "../../konstanter/http";
import {
    redux_user_profil,
    redux_user_profil_thunk,
}                                                   from "../../konstanter/redux";
import {
    url_api_get_player
}                                                   from "../../konstanter/url";

import {
    get_txt,
    err_msg_user_profil_redux_user_profil_feature_error,
    err_msg_redux_user_profil_feature_slice
}                                                   from "../../txt/txt";


const initialState                                  = {
    loading:        false,
    user_profil:    "",
    errorMessage:   null
};


export const getUserProfil                          = createAsyncThunk(redux_user_profil_thunk, async (arr) => { // console.log("> getUserProfil > id_arg :: ", id_arg)

    // Fetch - sok_user_profil_x
    const response                              = await fetch(url_api_get_player, {
        method                                  : http_method_post, // http_method_get,
        headers                                 : http_headers_content_type_application_json,
        body                                    : JSON.stringify({
            "username"                          : arr[0], // id_arg, // NEW
            "password"                          : arr[1], // password_reg,
            "email"                             : "",
            "api_tok"                           : ""
        })
    });

    // Data
    const data = await response.json()

    // Resopnse
    if (response.status === http_status_code_200) { // console.log("redux_user_profil_feature.js :: OK ")

        return data
    } else { // console.log("redux_user_profil_feature.js :: FAIL ")

        console.log(get_txt(
            err_msg_user_profil_redux_user_profil_feature_error,
            human_lang_default_hardkodet)
        );
    };

});


const userProfilSlice                               = createSlice({
    name:           redux_user_profil,
    initialState:   initialState,
    extraReducers: (builder) => {
        builder.addCase(getUserProfil.pending, (state) => {
            state.loading = true;
        }).addCase(getUserProfil.fulfilled, (state, action) => {
            state.loading = false;
            state.user_profil = action.payload;
        }).addCase(getUserProfil.rejected, (state) => {
            state.loading = false;
            state.errorMessage = get_txt(
                err_msg_redux_user_profil_feature_slice,
                human_lang_default_hardkodet);
        })
    }
});


export default userProfilSlice.reducer;
