// ************************************************ ...

import React                                        from "react";

import {Provider}                                   from "react-redux";
import {
    /// HashRouter as Router,
    BrowserRouter as Router,
    Routes,
    Route,
}                                                   from "react-router-dom";

// sokeord_link_loading /*
import "./static/css/_root.css";
import "./static/css/_bilde.css";
import "./static/css/_box.css";
import "./static/css/_btn.css";
import "./static/css/_cropper.css";
import "./static/css/_custom.css";
import "./static/css/_html_tag.css";
import "./static/css/_icon.css";
import "./static/css/_txt.css";
import "./static/css/01_header.css";
import "./static/css/02_footer.css";
// */

import Header                                       from "../src/components/_header/header";
//// import Path                                         from "../src/components/path/path";

import {
    route_root,

    route_mal,

    route_admin,

    route_doc,
    route_doc_router_pk,

    route_footer,

    route_black_screen,

    route_profil_disp_pk,
    route_profil_set,
    route_user_profil_list,

    route_sign_in,
    route_sign_in_email_confirm,
    route_sign_in_glemt_passord,
    route_sign_in_glemt_passord_confirm,

    route_sign_up,
}                                                   from "../src/konstanter/url";
import {
    reg_sign_in_email_confirm_typ,
    reg_sign_in_glemt_passord_typ,
    reg_sign_in_glemt_passord_confirm_typ,
    reg_sign_in_typ,
    reg_sign_up_typ,
}                                                   from "../src/konstanter/pages";


//// import Footer                                       from "../src/pages/footer/footer";

import {
    debug_app_root_initx
}                                                   from "../src/utils/debug_util";
import {
    tekst_debug_statement
}                                                   from "../src/utils/tekst_util";


import {AuthProvider}                               from "./context/auth_context";

import store                                        from "./redux/store";


const LandingPage           = React.lazy(() => import("./pages/landing/_Landing"));
const MalPage               = React.lazy(() => import("./pages/_mal/_Mal"));
const AdminPage             = React.lazy(() => import("./pages/admin/_Admin"));
const DocPage               = React.lazy(() => import("./pages/doc/_Doc"));
const DocRouterPage         = React.lazy(() => import("./pages/doc/_Doc_Router"));
const BlackScreenPage       = React.lazy(() => import("./pages/loading/_Black_Screen"));
const ProfilDispPage        = React.lazy(() => import("./pages/profil/_Profil_Disp"));
const ProfilSetPage         = React.lazy(() => import("./pages/profil/_Profil_Set"));
const UserProfilListPage    = React.lazy(() => import("./pages/profil/_User_Profil_List"));
const RegPage               = React.lazy(() => import("./pages/reg/_Reg"));
const FooterPage            = React.lazy(() => import("./pages/footer/_Footer"));


// Debug
if (debug_app_root_initx) {
    console.log(tekst_debug_statement("Initx", "0000", "App.js", "root", ""));
};


// DEV / Legacy > Sjekk at
// - link med route_doc_router_pk fungerer med pk-param og ikke slug
// - programmering.no og ikke autogazer


// App
function App() {


    // Return
    return (
        <Router>
            <Provider store={store}>
                <AuthProvider>
                    <div className="flex_app_hele_NEW">

                        {/* Header */}
                        <Header/>


                        {/* Path
                        <Path/>
                        */}

                        {/* Route Page */}
                        <div className="flex_app_body_NEW">
                            <React.Suspense fallback={"_fallback_hardkode"}>
                            <Routes>

                                <Route exact path={route_root}                                  element={<LandingPage/>}/>

                                <Route exact path={route_mal}                                   element={<MalPage/>}/>

                                <Route exact path={route_admin}                                 element={<AdminPage/>}/>

                                <Route exact path={route_doc}                                   element={<DocPage/>}/>
                                <Route exact path={`${route_doc_router_pk}:pk`}                 element={<DocRouterPage/>}/>

                                <Route exact path={route_black_screen}                          element={<BlackScreenPage/>}/>

                                <Route exact path={`${route_profil_disp_pk}:pk`}                element={<ProfilDispPage/>}/>
                                <Route exact path={route_profil_set}                            element={<ProfilSetPage/>}/>

                                <Route exact path={route_user_profil_list}                      element={<UserProfilListPage/>}/>

                                <Route exact path={route_sign_in}                               element={<RegPage typ={reg_sign_in_typ}/>}/>
                                <Route exact path={route_sign_in_email_confirm}                 element={<RegPage typ={reg_sign_in_email_confirm_typ}/>}/>
                                <Route exact path={route_sign_in_glemt_passord}                 element={<RegPage typ={reg_sign_in_glemt_passord_typ}/>}/>
                                <Route exact path={route_sign_in_glemt_passord_confirm}         element={<RegPage typ={reg_sign_in_glemt_passord_confirm_typ}/>}/>

                                <Route exact path={route_sign_up}                               element={<RegPage typ={reg_sign_up_typ}/>}/>

                                <Route exact path={route_footer}                                element={<FooterPage/>}/>

                            </Routes>

                            </React.Suspense>

                        </div>

                        {/* Footer
                        <Footer/>
                        */}



                    </div>
                </AuthProvider>
            </Provider>
        </Router>
    );
};


export default App;
