import {
    useCallback,
    createContext,
    useEffect,
    useState
}                                                   from "react";
import {
    useDispatch,
    useSelector
}                                                   from "react-redux";


import jwt_decode                                   from "jwt-decode";


import {
    setIsHeaderVisible
}                                                   from "../components/_header/redux_header_feature";
import {
    setPathIsVisible
}                                                   from "../components/path/redux_path_feature";

import {
    human_lang_default_hardkodet,
    reg_signin_page_root,
    reg_signin_page_profil_disp,
    reg_signin_page
}                                                   from "../innstillinger/innstillinger";

import {
    auth_user_id_jwt,
    auth_tokens,
    auth_postfix_local,
    auth_user_local,
    auth_user_id_felt_local,
    auth_is_superuser_local,
    auth_username_current_local,
    auth_img_profil_local,
    auth_full_name_local,
    auth_email_public_local,
    auth_bio_public_local,
    auth_url_public_local,
    auth_company_public_local,
    auth_poeng_local,
    auth_human_lang_local,
    auth_api_tok,
}                                                   from "../konstanter/context";
import {
    http_method_post,

    http_headers_content_type_application_json,

    http_status_code_200,
    http_status_code_400,
    http_status_code_401,
    http_status_code_404,
    http_status_code_405,
}                                                   from "../konstanter/http";
import {
    doc_ingen_blokk
}                                                   from "../konstanter/pages";
import {
    redux_account
}                                                   from "../konstanter/redux";
import {

    duration_token_refresh
}                                                   from "../konstanter/time";
import {
    json_root_pk,

    json_profil_disp_pk,

    json_sign_in_pk,

    url_api_token_refresh,
    url_api_token,

    url_api_get_player,

    route_root,

    route_profil_disp_pk,

    route_sign_in_email_confirm,
}                                                   from "../konstanter/url";

import {
    setDoNavigate
}                                                   from "../pages/doc/redux_doc_feature";
import {
    setIsLoadingCreateUserDone,
    setIsModalLoadingDone
}                                                   from "../pages/loading/redux_loading_feature";
import {
    setIsPasswordWrong
}                                                   from "../pages/profil/redux_account_feature";

import {
    get_txt,
    auth_msg_user_eksisterer_ikke_enda,
    err_msg_auth_context_signin_user_400_error,
    err_msg_auth_context_signin_user_401_error,
    err_msg_auth_context_signin_user_404_error,
    err_msg_auth_context_signin_user_405_error,
    err_msg_auth_context_signin_user_ukjent_status_kode,
    err_msg_auth_update_token_400_error,
    err_msg_auth_update_token_401_error,
    err_msg_auth_update_token_404_error,
    err_msg_auth_update_token_ukjent_status_kode
}                                                   from "../txt/txt";

import {
    debug_auth_initx,
    debug_auth_initx_cont,
    debug_auth_signup_fetch_signup_user,
    debug_auth_signup_fetch_get_user_profil_local_storage,
    debug_auth_fetch_confirmation_redirect,
    debug_auth_fetch_signout_user,
    debug_auth_fetch_await_signin_user,
}                                                   from "../utils/debug_util";
import {
    tekst_debug_statement
}                                                   from "../utils/tekst_util";


// AuthContext
const AuthContext                                   = createContext();
export default AuthContext;


// AuthProvider
export const AuthProvider                           = ({children}) => {

    // PRØV > PAUSE > Se README.txt > auth_context.js
    const _PAUSE_fetch_await_update_token           = true;

    // Redux
    const dispatch                                  = useDispatch();

    // User
    const {password_reg}                            = useSelector( (store) => {return store[redux_account]});

    // Loading
    const [loading_user, setLoadingUser]            = useState(true);
    const set_is_modal_loading_done                 = useCallback( () => {dispatch(setIsModalLoadingDone())}, [dispatch]);
    const set_is_loading_create_user_done           = useCallback( () => {dispatch(setIsLoadingCreateUserDone())}, [dispatch]);

    // Header
    const set_is_header_visible                     = (bl) => {dispatch(setIsHeaderVisible(bl))};
    const set_is_password_wrong                     = useCallback( (bl)  => {dispatch(setIsPasswordWrong(bl))}, [dispatch]);

    // Path
    const set_path_is_visible                       = useCallback( (typ) => {dispatch(setPathIsVisible(typ))}, [dispatch]);

    // Doc
    const set_do_navigate                            = useCallback( (arr) => {dispatch(setDoNavigate(arr))}, [dispatch]);

    // Token, user (set user), local storage
    const [authTokens, setAuthTokens]               = useState(
        () => localStorage.getItem(auth_tokens) ?
        JSON.parse(localStorage.getItem(auth_tokens)) : null
    );
    const [user, setUser]                           = useState(
        () => localStorage.getItem(auth_tokens) ?
        jwt_decode(localStorage.getItem(auth_tokens)) : null
    );
    const local_storage                             = (felt) => {
        return (
            localStorage.getItem(felt) ?
            JSON.parse(localStorage.getItem(felt)) : `${felt}${auth_postfix_local}`
        )
    };

    // User
    const [user_id_felt, setUserProfilIdFelt]       = useState(local_storage(auth_user_id_felt_local));
    const [is_superuser, setIsSuperuser]            = useState(local_storage(auth_is_superuser_local));
    const [username_current, setUsernameCurrent]    = useState(local_storage(auth_username_current_local));
    const [img_profil, setImgProfil]                = useState(local_storage(auth_img_profil_local));
    const [full_name, setFullName]                  = useState(local_storage(auth_full_name_local));
    const [email_public, setEmailPublic]            = useState(local_storage(auth_email_public_local));
    const [bio_public, setBioPublic]                = useState(local_storage(auth_bio_public_local));
    const [url_public, setUrlPublic]                = useState(local_storage(auth_url_public_local));
    const [company_public, setCompanyPublic]        = useState(local_storage(auth_company_public_local));
    const [poeng, setPoeng]                         = useState(local_storage(auth_poeng_local));
    const [human_lang, setHumanLang]                = useState(local_storage(auth_human_lang_local));
    const [api_tok, setApiTok]                      = useState(local_storage(auth_api_tok));

    // Signout > Local storage > Remove
    const signout_user                              = useCallback( () => {

        if (debug_auth_fetch_signout_user) {
            console.log("> 15. > auth_context.js > signout_user")
        };

        // Local storage
        localStorage.removeItem(auth_tokens);
        localStorage.removeItem(auth_user_local);
        localStorage.removeItem(auth_user_id_felt_local);
        localStorage.removeItem(auth_is_superuser_local);
        localStorage.removeItem(auth_username_current_local);
        localStorage.removeItem(auth_img_profil_local);
        localStorage.removeItem(auth_full_name_local);
        localStorage.removeItem(auth_email_public_local);
        localStorage.removeItem(auth_bio_public_local);
        localStorage.removeItem(auth_url_public_local);
        localStorage.removeItem(auth_company_public_local);
        localStorage.removeItem(auth_poeng_local);
        localStorage.removeItem(auth_human_lang_local);
        localStorage.removeItem(auth_api_tok);

        // Set > state
        setAuthTokens(null);
        setUser(null);
        setIsSuperuser(false);
        setUserProfilIdFelt("");
        setUsernameCurrent("");
        setImgProfil("");
        setFullName("");
        setEmailPublic("");
        setBioPublic("");
        setUrlPublic("");
        setCompanyPublic("");
        setPoeng("");
        setApiTok("");

        // Do_navigating
        set_do_navigate([
            0,
            route_root,
            json_root_pk]);

        // Loading > Modal > False
        set_is_modal_loading_done();

        // Dep
    }, [set_do_navigate,
        set_is_modal_loading_done]);

    // Fetch > User profil > Local storage > Add
    const fetch_get_user_profil_local_storage       = (username, is_create_user, api_tok) => { // Første page // console.log("> fetch_get_user_profil_local_storage > username :: ", username)

        // Debug
        if (debug_auth_signup_fetch_get_user_profil_local_storage) {
            console.log("> 8. > auth_context.js > fetch_get_user_profil_local_storage > username        :: ", username)
            console.log("> 9. > auth_context.js > fetch_get_user_profil_local_storage > is_create_user  :: ", is_create_user)
        };

        // Fetch
        fetch(url_api_get_player, {
            method                                  : http_method_post,
            headers                                 : http_headers_content_type_application_json,
            body                                    : JSON.stringify({
                "username"                          : username,
                "password"                          : password_reg,
                "email"                             : "",
                "api_tok"                           : api_tok
            })

        })
        .then((res) => res.json())
        .then(dat => {

            const _user_profil_is_created = true; // For debug (at siden kan loades uten noen brukere)
            if (_user_profil_is_created) {

                // Debug
                if (debug_auth_signup_fetch_get_user_profil_local_storage) {
                    console.log("> 10. > auth_context.js > fetch_get_user_profil_local_storage > dat :: ", dat)
                    console.log("> 11. > auth_context.js > fetch_get_user_profil_local_storage > dat.user :: ", dat.user)
                };

                // Set user profil > State
                setIsSuperuser(         dat.is_superuser)
                setUserProfilIdFelt(    dat.user); // Note: Ikke bruk dat.id
                setUsernameCurrent(     dat.username_current);
                setImgProfil(           dat.img_profil);
                setFullName(            dat.full_name);
                setEmailPublic(         dat.email_public);
                setBioPublic(           dat.bio_public);
                setUrlPublic(           dat.url_public);
                setCompanyPublic(       dat.company_public);
                setPoeng(               dat.poeng);
                setHumanLang(           dat.human_lang);
                setApiTok(              dat.api_tok);

                // Set user profil > Locla storage
                localStorage.setItem(auth_user_id_felt_local,       String(JSON.stringify(dat.user)));
                localStorage.setItem(auth_is_superuser_local,       JSON.stringify(dat.is_superuser));
                localStorage.setItem(auth_username_current_local,   JSON.stringify(dat.username_current));
                localStorage.setItem(auth_img_profil_local,         JSON.stringify(dat.img_profil));
                localStorage.setItem(auth_full_name_local,          JSON.stringify(dat.full_name));
                localStorage.setItem(auth_email_public_local,       JSON.stringify(dat.email_public));
                localStorage.setItem(auth_bio_public_local,         JSON.stringify(dat.bio_public));
                localStorage.setItem(auth_url_public_local,         JSON.stringify(dat.url_public));
                localStorage.setItem(auth_company_public_local,     JSON.stringify(dat.company_public));
                localStorage.setItem(auth_poeng_local,              JSON.stringify(dat.poeng));
                localStorage.setItem(auth_human_lang_local,         JSON.stringify(dat.human_lang));
                localStorage.setItem(auth_api_tok,                  JSON.stringify(dat.api_tok));
            };

            // Create user > True > Lag ny user
            if (is_create_user === true) {

                // Debug
                if (debug_auth_signup_fetch_get_user_profil_local_storage) {
                    console.log("> 12a. > auth_context.js > fetch_get_user_profil_local_storage > create user === true > dat :: ", dat)
                };

                // Fetch confirmation redirect
                fetch_confirmation_redirect(username);

            };

            // Create user > False > User eksisterer > Signin
            if (is_create_user === false) { // -> Logg inn første side

                // Debug
                if (debug_auth_signup_fetch_get_user_profil_local_storage) {
                    console.log("> 12b. > auth_context.js > fetch_get_user_profil_local_storage > create user === false > is_create_user :: ", is_create_user)
                };

                // Setting > Signin > Profil disp
                if (reg_signin_page === reg_signin_page_profil_disp) {

                    // Debug
                    if (debug_auth_signup_fetch_get_user_profil_local_storage) {
                        console.log("> 13a. > auth_context.js > fetch_get_user_profil_local_storage > reg_signin_page === reg_signin_page_profil_disp > reg_signin_page :: ", reg_signin_page)
                    };

                    // Do_navigating
                    set_do_navigate([
                        0,
                        `${route_profil_disp_pk}${username}`,
                        json_profil_disp_pk]);

                };

                // Setting > Signin > Ladning
                if (reg_signin_page === reg_signin_page_root) {

                    // Debug
                    if (debug_auth_signup_fetch_get_user_profil_local_storage) {
                        console.log("> 13b. > auth_context.js > fetch_get_user_profil_local_storage > reg_signin_page === reg_signin_page_root")
                    };

                    // Do_navigating
                    set_do_navigate([
                        0,
                        route_root,
                        json_root_pk]);

                };

                // Visibile
                set_path_is_visible(true);
                set_is_header_visible(true); // Her for ikke å få 404-feil, regner ut header_yy (selv om allerede er synlig)

            };

        })

    };

    // Fetch > Signup
    const fetch_confirmation_redirect               = (username) => {

        // Debug
        if (debug_auth_fetch_confirmation_redirect) {
            console.log("> 13-14. > auth_context.js > fetch_confirmation_redirect > username :: ", username)
        };

        // Signout
        signout_user();

        // Do_navigating
        set_do_navigate([
            doc_ingen_blokk,
            route_sign_in_email_confirm,
            json_sign_in_pk]);

        // Loading > Create user > True
        set_is_loading_create_user_done();

    };
    const fetch_signup_user                         = (username, api_tok) => {

        // Debug
        if (debug_auth_signup_fetch_signup_user) {
            console.log("> 7. > auth_context.js > fetch_signup_user > username  :: ", username)
            console.log("> 7. > auth_context.js > fetch_signup_user > api_tok   :: ", api_tok)
        };

        // User profil > Get local storage
        fetch_get_user_profil_local_storage(username, true, api_tok);

    };

    // Fetch > Signin
    const fetch_await_signin_user                   = async (username, password, api_tok) => {

        // - sok_url_api_token_keys
        // - "api_tok" skal ikke sendes inn som arg het

        // Debug
        if (debug_auth_fetch_await_signin_user) {
            console.log("> 1. > auth_context.js > fetch_await_signin_user > username :: ", username)
            console.log("> 2. > auth_context.js > fetch_await_signin_user > password :: ", password)
        };

        // Await > Response
        const _res                                  = await fetch(url_api_token, {
            method                                  : http_method_post,
            headers                                 : http_headers_content_type_application_json,
            body                                    : JSON.stringify({
                "username"                          : username,
                "password"                          : password,
            })

        })

        // Await > Data
        const _data = await _res.json()

        /* 200 > OK > Set auth tokens */
        if (_res.status === http_status_code_200) { // Login > OK

            // Debug
            if (debug_auth_fetch_await_signin_user) {
                console.log("> 3a. > auth_context.js > fetch_await_signin_user > 200 > res.status :: ", _res.status)
            };


            // console.log("DATA ::: ", data)

            // Password > Correct
            set_is_password_wrong(false);

            // Auth tokens
            setAuthTokens(_data);

            // Auth tokens > Local storage
            localStorage.setItem(auth_tokens, JSON.stringify(_data));

            // User > Access
            setUser(jwt_decode(_data.access));

            // User > ID
            const usr_id = jwt_decode(_data.access)[auth_user_id_jwt]; // console.log("username > usr_id :: ", usr_id)


            // User > ID > Hack > Type caster fra abc til "abc"
            var usr_id_hack = {
                auth_user_id_felt_local: `${String(usr_id)}`
            };

            // User > ID > Local storage
            localStorage.setItem(`${auth_user_id_felt_local}`, JSON.stringify(usr_id_hack));

            // Fetch user profil
            fetch_get_user_profil_local_storage(username, false);}

        /* 400 > Feil brukernavn / passord */
        else if (_res.status === http_status_code_400) { // Feil brukernavn / passord

            // Debug
            if (debug_auth_fetch_await_signin_user) {
                console.log("> 3b. > auth_context.js > fetch_await_signin_user > 400 > res.status :: ",                     _res.status)};

            // Password
            set_is_password_wrong(true);

            // Console
            console.log(get_txt(err_msg_auth_context_signin_user_400_error, human_lang_default_hardkodet),                  _res.status);}

        /* 401 > Feil brukernavn / passord */
        else if (_res.status === http_status_code_401) {

            // Debug
            if (debug_auth_fetch_await_signin_user) {
                console.log("> 3c. > auth_context.js > fetch_await_signin_user > 401 > res.status :: ",                     _res.status)};

            // Password
            set_is_password_wrong(true);

            // Console
            console.log(get_txt(err_msg_auth_context_signin_user_401_error, human_lang_default_hardkodet),                  _res.status);}

        /* 404 > Nettsiden finnes ikke */
        else if (_res.status === http_status_code_404) {

            // Debug
            if (debug_auth_fetch_await_signin_user) {
                console.log("> 3d. > auth_context.js > fetch_await_signin_user > 404 > res.status :: ",                     _res.status)};

            // Console
            console.log(get_txt(err_msg_auth_context_signin_user_404_error, human_lang_default_hardkodet),                  _res.status);}

        /* 405 > Method not allowed */
        else if (_res.status === http_status_code_405) {

            // Debug
            if (debug_auth_fetch_await_signin_user) {
                console.log("> 3e. > auth_context.js > fetch_await_signin_user > 405 > res.status :: ",                     _res.status)};

            // Console
            console.log(get_txt(err_msg_auth_context_signin_user_405_error, human_lang_default_hardkodet),                  _res.status);}

        /* Default > Ukjent status kode */
        else {

            // Debug
            if (debug_auth_fetch_await_signin_user) {
                console.log("> 3f. > auth_context.js > fetch_await_signin_user > Ukjent status kode > res.status :: ",      _res.status)};

            // Console
            console.log(get_txt(err_msg_auth_context_signin_user_ukjent_status_kode, human_lang_default_hardkodet),         _res.status);};

        // Loading > Modal > Done
        set_is_modal_loading_done();

    };

    // Fetch > Update tokens
    const fetch_await_update_token                  = useCallback( async () => {

        // Debug
        if (debug_auth_fetch_await_signin_user) {
            console.log("> xx. > auth_context.js > fetch_await_update_token")
        };

        /* User > Eksisterer > Fetch update tokens */
        if (user && _PAUSE_fetch_await_update_token === false) {

            // console.log("TOK :: ", authTokens?.refresh)
            // console.log("URL :: ", url_api_token_refresh)

            // Await > Response
            const res = await fetch(url_api_token_refresh, {
                method:     http_method_post,
                headers:    http_headers_content_type_application_json,
                body: JSON.stringify({
                    "refresh":  authTokens?.refresh
                })
            })

            // Await > Data
            const dat = await res.json()

            /* 200 > OK > Update auth tokens */
            if (res.status === 200) {
                setAuthTokens(dat);
                setUser(jwt_decode(dat.access));
                localStorage.setItem(auth_tokens, JSON.stringify(dat));}

            /* 400 > Feil brukernavn / passord */
            else if (res.status === 400) {
                console.log(get_txt(err_msg_auth_update_token_400_error, human_lang_default_hardkodet), res.status);}

            /* 401 > Feil brukernavn / passord */
            else if (res.status === 401) {
                console.log(get_txt(err_msg_auth_update_token_401_error, human_lang_default_hardkodet), res.status);}

            /* 404 > Nettsiden finnes ikke */
            else if (res.status === 404) {
                console.log(get_txt(err_msg_auth_update_token_404_error, human_lang_default_hardkodet), res.status);}

            /* Default > Ukjent status kode > Signout user */
            else {
                console.log(get_txt(err_msg_auth_update_token_ukjent_status_kode, human_lang_default_hardkodet), res.status)
                signout_user();};}

        /* User > Eksisterer ikke enda */
        else {

            // Debug > Initx
            if (debug_auth_initx === true) {
                console.log(tekst_debug_statement("Initx", "0000", "auth_context.js", "fetch_await_update_token", "Ingen user"));
            };

            // Debug > Cont
            if (debug_auth_initx_cont === true) {
                const _err = get_txt(auth_msg_user_eksisterer_ikke_enda, human_lang_default_hardkodet);
                console.log(`Initx > 0000 ${_err}`);
            };

        };

        // Loading > User > False
        if (loading_user === true) {
            setLoadingUser(false);
        };

        // Dep
    }, [user,
        authTokens?.refresh,
        loading_user,
        _PAUSE_fetch_await_update_token,
        signout_user]);

    // Context data
    const contextData                               = {
        user:                                   user,
        authTokens:                             authTokens,
        fetch_signup_user:                      fetch_signup_user,
        fetch_await_signin_user:                fetch_await_signin_user,
        signout_user:                           signout_user,
        user_id_felt:                           String(user_id_felt),
        is_superuser:                           is_superuser,
        username_current:                       username_current,
        img_profil:                             img_profil,
        full_name:                              full_name,
        email_public:                           email_public,
        bio_public:                             bio_public,
        url_public:                             url_public,
        company_public:                         company_public,
        poeng:                                  poeng,
        human_lang:                             human_lang,
        api_tok:                                api_tok,
        setUsernameCurrent:                     setUsernameCurrent,
        setImgProfil:                           setImgProfil,
        setFullName:                            setFullName,
        setEmailPublic:                         setEmailPublic,
        setBioPublic:                           setBioPublic,
        setUrlPublic:                           setUrlPublic,
        setCompanyPublic:                       setCompanyPublic,
        fetch_get_user_profil_local_storage:    fetch_get_user_profil_local_storage,
        /// check_storage:                          check_storage
    };

    // useEffect
    useEffect( () => {

        // Loading > User > True (default) > Update tokens første gangen
        if (loading_user === true) {
            fetch_await_update_token();
        };

        // Refresh token > Interval > Start > Duration > 4 min
        const interval =  setInterval( () => {
            if (authTokens) {
                fetch_await_update_token();
            };
        }, duration_token_refresh);

        // Return > Clear / Restart interval
        return () => clearInterval(interval)

        // Dep
    }, [fetch_await_update_token,
        authTokens,
        loading_user]);

    // Return
    return (
        <AuthContext.Provider value = {contextData} >
            {loading_user ? null : children}
        </AuthContext.Provider>
    )

};
